import _, { ValueIteratee } from 'lodash';

export function groupBy<T>(items: T[], iteratee?: ValueIteratee<T>) {
  let groupedRecords = _(items).groupBy(iteratee).map((items, key) => {
      return {
        key,
        items
      };
    });

  return groupedRecords.value();
}